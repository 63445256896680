import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex flex-gap-16"},[_c('shipping-method-action-reindex',{on:{"success":_vm.reloadCollection,"error":_vm.onRequestError}}),(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'shipping-method-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new shipping method'))+" ")]):_vm._e()],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'shipping-method-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(item.enabled ? 'done' : 'close'))])]}},{key:"item.price.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.price}})]}},{key:"item.gross_price.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.gross_price}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'shipping-method-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('shipping-method-action-delete',{attrs:{"icon":"","value":item},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Shipping methods') },false))}
var staticRenderFns = []

export { render, staticRenderFns }